import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from 'components/App'
import NotificationProvider from 'components/Notification/NotificationProvider'

import 'styles/colors.css'

import 'css/main.css'
import 'css/media.css'
import 'css/normalize.css'
import 'css/reset.css'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')

ReactDOM.render(
	<Router basename={baseUrl}>
		<NotificationProvider>
			<App />
		</NotificationProvider>
	</Router>,
	rootElement
)
