import { React } from 'components/App'

import './styles/Preloader.css'

export function Preloader(props) {
	let win8 = (
		<div className='windows8'>
			<div className='wBall' id='wBall_1'>
				<div className='wInnerBall'></div>
			</div>
			<div className='wBall' id='wBall_2'>
				<div className='wInnerBall'></div>
			</div>
			<div className='wBall' id='wBall_3'>
				<div className='wInnerBall'></div>
			</div>
			<div className='wBall' id='wBall_4'>
				<div className='wInnerBall'></div>
			</div>
			<div className='wBall' id='wBall_5'>
				<div className='wInnerBall'></div>
			</div>
		</div>
	)

	if (!props.isSignedIn) {
		return (
			<main className='preloader-wrapper'>
				<div className='preloader'>{win8}</div>
			</main>
		)
	} else {
		return <div className='preloader'>{win8}</div>
	}
}
