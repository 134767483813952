import React, {
	Component,
	PureComponent,
	Suspense,
	lazy,
	createContext,
} from 'react'
import { useState, useEffect, useContext, useRef } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import PubSub from 'pubsub-js'
import { hubConnection } from 'aspnet-signalr-reactjs'
import Settings from 'Settings'
import { Preloader } from 'components/Preloader'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { sendMetrik } from 'helpers/Metriks'

const PrivateRoute = lazy(() => import('components/PrivateRoute'))
const Main = lazy(() => lazyRetry(() => import('components/main/Main')));
const NotFound = lazy(() => import('components/NotFound/NotFound'))
const Landing = lazy(() => import('components/Landing/Landing'))
const ProcessingOfPersonalData = lazy(() =>
	import('components/Landing/ProcessingOfPersonalData')
)
const Login = lazy(() => lazyRetry(() => import('components/Login/Login')));
const ForgotPassword = lazy(() =>
	import('components/ForgotPassword/ForgotPassword')
)

function lazyRetry(fn, delay = 1000, retriesLeft = 5) {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						reject(error);
						return;
					}

					// Повторная попытка с уменьшением количества оставшихся попыток
					lazyRetry(fn, delay, retriesLeft - 1).then(resolve, reject);
				}, delay);
			});
	});
}

export {
	React,
	Component,
	PureComponent,
	Switch,
	Route,
	Redirect,
	Link,
	Suspense,
	sendMetrik,
}
export { ReactTooltip, PubSub, hubConnection }
export { createContext, lazy }
export { useState, useEffect, useContext, useHistory, useLocation, useRef }

class App extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isSignedIn: null, // залогинен ли пользователь
		}

		this.setSignedIn = this.setSignedIn.bind(this)
		this.checkAuth = this.checkAuth.bind(this)

		this.api_regex = /^\/api\/.*/
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			sendMetrik('hit', window.location.href)
		}
	}

	async componentDidMount() {
		// если заходим на ./api/... , то игнорируем проверку авторизации
		if (!this.api_regex.test(this.props.location.pathname)) {
			await this.checkAuth()
		}
	}

	render() {
		// если заходим на ./api/... , то игнорируем обычный роутинг
		if (!this.api_regex.test(this.props.location.pathname)) {
			if (this.state.isSignedIn == null) {
				return <Preloader isSignedIn={false} />
			} else {
				return (
					<ErrorBoundary>
						<Suspense fallback={<Preloader isSignedIn={false} />}>
							<Switch>
								<Route exact path='/' component={() => <Landing />} />
								<Route
									exact
									path='/ForgotPassword'
									component={ForgotPassword}
								/>
								<Route
									exact
									path='/ProcessingOfPersonalData'
									component={ProcessingOfPersonalData}
								/>
								<Route
									exact
									path='/Login/:credentials?'
									component={props => (
										<Login
											setSignedIn={this.setSignedIn}
											isSignedIn={this.state.isSignedIn}
											{...props}
										/>
									)}
								/>
								<PrivateRoute
									path='/Main'
									component={Main}
									isSignedIn={this.state.isSignedIn}
								/>
								<Route
									component={() => (
										<NotFound isSignedIn={this.state.isSignedIn} />
									)}
								/>
							</Switch>
						</Suspense>
					</ErrorBoundary>
				)
			}
		} else {
			return <div />
		}
	}

	// проверить авторизацию
	async checkAuth() {
		try {
			const response = await fetch('App/checkAuth')

			if (!response.ok) throw Error(response.statusText)

			const data = await response.json()

			this.setState({ isSignedIn: data.isAuth })
		} catch (error) {
			this.context('Error', Settings.errorMessage)
			console.error(error)
		}
	}

	// установить флаг состояния "Залогинен ли пользователь" в противоположное значение
	setSignedIn() {
		this.setState(prevState => ({ isSignedIn: !prevState.isSignedIn }))
	}
}

export default withRouter(App)
