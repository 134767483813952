import { React, PureComponent } from 'components/App'

export default class Notification extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			width: 0,
			close: false,
			timerId: null,
		}

		this.closeNotify = this.closeNotify.bind(this)
		this.setWidth = this.setWidth.bind(this)
		this.startTimer = this.startTimer.bind(this)
		this.pauseTimer = this.pauseTimer.bind(this)
	}

	render() {
		return (
			<div
				onMouseEnter={this.pauseTimer}
				onMouseLeave={this.startTimer}
				onClick={this.closeNotify}
				className={`notification-item ${
					this.state.close === true ? 'exit' : ''
				} ${this.props.type === 'Success' ? 'success' : 'error'}`}
			>
				<p>{this.props.message}</p>
				<div className={'bar'} style={{ width: `${this.state.width}%` }} />
			</div>
		)
	}

	startTimer() {
		this.setState({
			timerId: setInterval(() => {
				this.setState({
					width: this.setWidth(),
				})
			}, 20),
		})
	}

	setWidth() {
		if (this.state.width < 100) {
			return this.state.width + 0.25
		} else {
			this.closeNotify()
			return this.state.width
		}
	}

	closeNotify() {
		this.pauseTimer()
		this.setState({ close: true })
		setTimeout(() => {
			this.props.remove(this.props.id)
		}, 300)
	}

	pauseTimer() {
		clearInterval(this.state.timerId)
	}

	componentDidMount() {
		this.startTimer()
	}

	componentWillUnmount() {
		this.closeNotify()
	}
}
