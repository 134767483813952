import { React, Component, lazy } from 'components/App'
import Settings from 'Settings'
import { NotificationContext } from 'components/Notification/NotificationProvider'

const NotFound = lazy(() => import('components/NotFound/NotFound'))

// Предохранитель
export class ErrorBoundary extends Component {
	constructor(props) {
		super(props)

		this.state = {
			hasError: false, // возникла ли ошибка
		}
	}

	async componentDidCatch(error, info) {
		this.setState({ hasError: true })

		try {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: new URLSearchParams({
					errorStack: error.stack,
					infoComponentStack: info.componentStack,
				}),
			}

			const response = await fetch(
				'ErrorBoundary/registerError',
				requestOptions
			)

			if (!response.ok) throw Error(response.statusText)

			const data = await response.json()

			if (data.error) {
				this.context('Error', data.error)
				console.error(data.error)
				return
			}
		} catch (error) {
			this.context('Error', Settings.errorMessage)
			console.error(error)
		}
	}

	render() {
		if (this.state.hasError) {
			return <NotFound isSignedIn='false' />
		}

		return this.props.children
	}
}

ErrorBoundary.contextType = NotificationContext
