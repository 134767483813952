import { React, Component, createContext } from 'components/App'
import { v4 } from 'uuid'
import Notification from 'components/Notification/Notification'

export const NotificationContext = createContext()

export default class NotificationProvider extends Component {
	constructor(props) {
		super(props)

		this.state = {
			notifies: [],
		}

		this.addNotification = this.addNotification.bind(this)
		this.removeNotification = this.removeNotification.bind(this)
	}

	render() {
		return (
			<NotificationContext.Provider value={this.addNotification}>
				{this.props.children}
				<div className={'notification-wrapper'}>
					{this.state.notifies.map(note => (
						<Notification
							remove={this.removeNotification}
							key={note.id}
							{...note}
						/>
					))}
				</div>
			</NotificationContext.Provider>
		)
	}

	addNotification(type, message) {
		var newItem = {
			id: v4(),
			type: type,
			message: message,
		}
		this.setState(prevState => ({
			notifies: prevState.notifies.concat(newItem),
		}))
	}

	removeNotification(id) {
		this.setState(prevState => ({
			notifies: prevState.notifies.filter(note => id !== note.id),
		}))
	}
}
